import { getMailDomainBaseURL, getNotionDomainBaseURL } from '../../env.utils';

export const AUTH_CHANNEL = 'auth_channel';
export const INITIAL_HANDSHAKE = 'initial_handshake';

export const AuthSync: React.FC = () => {
  // This is the frontend code that is responsible for syncing the localSession data with the parent window.
  // It subscribes to the auth_channel BroadcastChannel and pushes messages to the parent window.
  // See https://dev.notion.so/notion/Tech-Spec-Consistent-Multi-Product-Account-Switcher-680b628266874729871b7af5770b59f3
  const bc = new BroadcastChannel(AUTH_CHANNEL);
  const messageChannel = new MessageChannel();

  const handleInitialHandshake = (event: MessageEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data, origin } = event;
    console.log('Auth sync event', event);
    if (origin !== getMailDomainBaseURL() && origin !== getNotionDomainBaseURL()) {
      return;
    }

    if (data === INITIAL_HANDSHAKE) {
      window.parent.postMessage({ channel: AUTH_CHANNEL }, origin, [messageChannel.port2]);
      window.removeEventListener('message', handleInitialHandshake);
    }
  };

  window.addEventListener('message', handleInitialHandshake);

  bc.onmessage = (event) => {
    messageChannel.port1.postMessage(event.data);
  };

  messageChannel.port1.onmessage = (event) => {
    bc.postMessage(event.data);
  };

  return null;
};
