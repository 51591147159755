import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './AppRoutes';

function App() {
  return (
    <main className='app'>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </main>
  );
}

export default App;
