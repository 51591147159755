import { Route, Routes } from 'react-router-dom';

import { AuthSync } from './components/AuthSync';
import { IdentityAppRoutes } from './constants';

export default function AppRoutes() {
  return (
    <Routes>
      <Route element={<AuthSync />} path={IdentityAppRoutes.AUTH_SYNC} />
      <Route element={<></>} path={IdentityAppRoutes.HOME} />
    </Routes>
  );
}
